
import { UserMeDto } from '../../../models/dtos/userMe.dto';
import axios from '../../../utilities/axios';

export const getMe = async (): Promise<UserMeDto> => {
  try {
    const response = await axios.get<UserMeDto>(
      `${process.env.REACT_APP_BASE_URL}/users/me`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
