import './Loading.scss';

interface IProps {
  className?: string;
  fontSize?: number;
}

const Loading = (props: IProps) => {

  return (
    <div
      id='loading'
      className={props.className}
    >

    </div>
  );
};

export default Loading;
