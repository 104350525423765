import axios from '../../../utilities/axios';

export const sendOtpRequest = async (msisdn: string): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/external-wheel/otp`,
      {
        msisdn
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
