import {
  UPDATE_USER_FAIL,
  UPDATE_USER_RESET,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS
} from "./constants";
import { UpdateUserActions, UpdateUserState } from "./types";

const initialState: UpdateUserState = {
  loading: false,
  data: false,
  error: undefined
}

const reducer = (
  state = initialState,
  action: UpdateUserActions,
): UpdateUserState => {
  switch (action.type) {
    case UPDATE_USER_START:
      return {
        ...initialState,
        loading: true
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case UPDATE_USER_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default reducer;
