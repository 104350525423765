import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import "./App.scss";
import AppLayout from "./components/Layouts/AppLayout/AppLayout";
import Loading from "./components/Loading/Loading";
import ApiErrorModal from "./components/Modals/ApiErrorModal/ApiErrorModal";
import HomePage from "./containers/HomePage/HomePage";
import WheelPage from "./containers/WheelPage/WheelPage";
import { ErrorDto } from "./core/models/dtos/error.dto";
import { setApiError } from "./core/services/appService/setApiError/actions";
import { setAppMounted } from "./core/services/appService/setAppMounted/actions";
import { SetAppMountedState } from "./core/services/appService/setAppMounted/types";
import { LoginMsisdnState } from "./core/services/externalWheelService/loginMsisdn/types";
import { setRemoteConfig } from "./core/services/firebaseService/setRemoteConfig/actions";
import { SetRemoteConfigState } from "./core/services/firebaseService/setRemoteConfig/types";
import { UpdateUserState } from "./core/services/userService/updateUser/types";
import axios from "./core/utilities/axios";
import { Errors } from "./core/utilities/errors";
import { history } from "./core/utilities/history";
import { IStore } from "./core/utilities/reducers";
import { router } from "./core/utilities/router";

interface IProps {
  setAppMountedState: SetAppMountedState;
  setAppMounted: (isMounted: boolean) => void;
  setRemoteConfigState: SetRemoteConfigState;
  setRemoteConfig: () => void;
  setApiError: (error?: ErrorDto) => void;
  loginMsisdnState: LoginMsisdnState;
  updateUserState: UpdateUserState;
}

interface IState {
  callbackHistoryUnregister?: VoidFunction;
}

class App extends React.Component<IProps> {
  state: IState = {
    callbackHistoryUnregister: undefined,
  };

  constructor(props: IProps) {
    super(props);
    this.setAxiosInterceptor();
    this.props.setRemoteConfig();
  }

  componentDidMount() {
    this.props.setAppMounted(true);
  }

  private setAxiosInterceptor(): void {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (
          !Errors.isAuthError(error) &&
          !Errors.isKnownError(error) &&
          !this.props.updateUserState.error
        ) {
          this.props.setApiError(Errors.getErrorDtoFromApiError(error));
        }
        return Promise.reject(error);
      }
    );
  }

  private isAppLoading(): boolean {
    return (
      !this.props.setAppMountedState.isMounted ||
      this.props.setRemoteConfigState.loading
    );
  }

  private get isLoggedIn(): boolean {
    return !!this.props.loginMsisdnState.data?.hash;
  }

  render() {
    return (
      <Router history={history}>
        {this.isAppLoading() ? (
          <Loading className="app-loading" fontSize={80} />
        ) : (
          <AppLayout>
            <Switch>
              <Route
                exact
                path={router.HOME}
                component={this.isLoggedIn ? WheelPage : HomePage}
              />
              <Redirect to={router.HOME} />
            </Switch>
          </AppLayout>
        )}
        <ApiErrorModal />
      </Router>
    );
  }

  componentWillUnmount() {
    if (this.state.callbackHistoryUnregister) {
      this.state.callbackHistoryUnregister();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setAppMounted,
      setRemoteConfig,
      setApiError,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setAppMountedState: store.setAppMounted,
    setRemoteConfigState: store.setRemoteConfig,
    loginMsisdnState: store.loginMsisdn,
    updateUserState: store.updateUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
