import { Dispatch } from "redux";
import { ErrorDto } from "../../../models/dtos/error.dto";
import { UserRequest } from "../../../models/requests/user.request";
import { Errors } from "../../../utilities/errors";
import {
  UPDATE_USER_FAIL,
  UPDATE_USER_RESET,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS
} from "./constants";
import { updateUser } from "./repository";
import {
  UpdateUserFailAction,
  UpdateUserResetAction,
  UpdateUserStartAction,
  UpdateUserSuccessAction
} from "./types";

const updateUserStartAction = (): UpdateUserStartAction => {
  return {
    type: UPDATE_USER_START,
  }
}
const updateUserSuccessAction = (payload: boolean): UpdateUserSuccessAction => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: payload,
  }
}
const updateUserFailAction = (error: ErrorDto): UpdateUserFailAction => {
  return {
    type: UPDATE_USER_FAIL,
    error: error,
  }
}
export const updateUserDetail = (userRequest: UserRequest) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(updateUserStartAction());
    await updateUser(userRequest);
    dispatch(updateUserSuccessAction(true));
  } catch (error) {
    dispatch(updateUserFailAction(Errors.getErrorDtoFromApiError(error)));
  }
}

const updateUserResetAction = (): UpdateUserResetAction => {
  return {
    type: UPDATE_USER_RESET,
  }
}
export const updateUserReset = () => (
  dispatch: Dispatch
) => {
  dispatch(updateUserResetAction());
}
