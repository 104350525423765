import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import smsIconError from "../../../assets/images/icon-sms-code-error.svg";
import smsIcon from "../../../assets/images/icon-sms-code.svg";
import { LoginMsisdnRequest } from "../../../core/models/requests/loginMsisdn.request";
import {
  loginMsisdn,
  loginMsisdnReset,
} from "../../../core/services/externalWheelService/loginMsisdn/actions";
import { LoginMsisdnState } from "../../../core/services/externalWheelService/loginMsisdn/types";
import { sendOtpReset } from "../../../core/services/externalWheelService/sendOtp/actions";
import { SendOtpState } from "../../../core/services/externalWheelService/sendOtp/types";
import { IStore } from "../../../core/utilities/reducers";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./OtpModal.scss";

interface IProps {
  sendOtpState: SendOtpState;
  loginMsisdnState: LoginMsisdnState;
  sendOtpReset: () => void;
  loginMsisdn: (loginRequest: LoginMsisdnRequest) => void;
  loginMsisdnReset: () => void;
}

const OtpModal = (props: IProps) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.loginMsisdnState.error) {
      form.resetFields();
      setVerificationCode("");
    }
  }, [form, props.loginMsisdnState]);

  const isModalVisible = (): boolean => {
    return (
      !props.sendOtpState.loading &&
      (!!props.sendOtpState.data ||
        props.loginMsisdnState.error?.status === 401)
    );
  };

  const handleCancelModal = () => {
    props.sendOtpReset();
    props.loginMsisdnReset();
  };

  const onFinish = (values: { otp: string }) => {
    const loginRequest: LoginMsisdnRequest = {
      msisdn: props.sendOtpState.data?.msisdn || "",
      otp: values.otp,
      marketingConsent: props.sendOtpState.data?.marketingConsent || false,
    };
    props.loginMsisdn(loginRequest);
  };

  const handleMaxLength = (e: { target: { value: string } }) => {
    const limit = 4;
    setVerificationCode(e.target.value.slice(0, limit));
  };

  const renderLogo = (): string => {
    if (
      props.loginMsisdnState.error &&
      props.loginMsisdnState.error.status === 401
    ) {
      return smsIconError;
    }
    return smsIcon;
  };

  const renderTitle = (): string => {
    if (
      props.loginMsisdnState.error &&
      props.loginMsisdnState.error.status === 401
    ) {
      return "Tekrar dene";
    }
    return "Doğrulama kodunu gir";
  };

  const renderText = (): string => {
    if (
      props.loginMsisdnState.error &&
      props.loginMsisdnState.error.status === 401
    ) {
      return "Numaran doğrulanamadı. Lütfen gönderdiğimiz kodu kontrol ederek tekrar gir.";
    }
    return "Numaranı doğrulamamız için sana SMS ile gönderdiğimiz 4 haneli kodu gir.";
  };

  const renderModalContent = (): React.ReactNode => {
    return (
      <div className="content">
        <img className="logo sms-icon" src={renderLogo()} alt="sms" />
        <p className="title mb-4">{renderTitle()}</p>
        <p className="text">{renderText()}</p>
        <Form
          id="otp-form"
          className="app-form mt-2"
          name="otp-form"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="otp"
            rules={[
              { required: true, message: "" },
              { min: 4, message: "" },
            ]}
          >
            <Input
              placeholder="xxxx"
              type="number"
              value={verificationCode}
              onChange={handleMaxLength}
            />
          </Form.Item>
          <Form.Item>
            <ButtonPrimary
              text="Onayla"
              loading={props.loginMsisdnState.loading}
              isSubmit={true}
            />
          </Form.Item>
        </Form>
      </div>
    );
  };
  return (
    <Modal
      className="otp-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={isModalVisible()}
      closable={true}
      centered
      footer={false}
      destroyOnClose
      width={342}
      onCancel={handleCancelModal}
    >
      <div className="body">{renderModalContent()}</div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      sendOtpReset,
      loginMsisdn,
      loginMsisdnReset,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    sendOtpState: store.sendOtp,
    loginMsisdnState: store.loginMsisdn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpModal);
