import { Button } from "antd";
import { ReactNode } from "react";
import "./ButtonPrimary.scss";

interface IProps {
    text: ReactNode;
    class?: string;
    loading?: boolean;
    isSubmit?: boolean;
    callback?: Function;
}

const ButtonPrimary = (props: IProps) => {

    return (
        <Button
            id="button-primary"
            type="primary"
            className={props.class ?? ''}
            loading={props.loading}
            htmlType={props.isSubmit ? "submit" : "button"}
            onClick={() => props.callback ? props.callback() : {}}
        >
            <div className="d-flex">
                <span>{props.text}</span>
            </div>
        </Button>
    );
}

export default ButtonPrimary;
