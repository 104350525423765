import { Button, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ErrorDto } from '../../../core/models/dtos/error.dto';
import { setApiError } from '../../../core/services/appService/setApiError/actions';
import { SetApiErrorState } from '../../../core/services/appService/setApiError/types';
import { updateUserReset } from '../../../core/services/userService/updateUser/actions';
import { UpdateUserState } from '../../../core/services/userService/updateUser/types';
import { Errors } from '../../../core/utilities/errors';
import { IStore } from '../../../core/utilities/reducers';
import { UI } from '../../../core/utilities/ui';
import './ApiErrorModal.scss';

interface IProps {
  apiErrorState: SetApiErrorState;
  updateUserState: UpdateUserState;
  setApiError: (error?: ErrorDto) => void;
  updateUserReset: () => void;
}

const ApiErrorModal = (props: IProps) => {
  const isVisible = (): boolean => {
    return !!props.apiErrorState.error || !!props.updateUserState.error;
  };

  const getTitle = (): string => {
    if (props.updateUserState.error) {
      return "Hata";
    }
    return Errors.errorGenericTitle;
  };

  const getText = (): string => {
    if (props.updateUserState.error) {
      return "Bilgilerin, Taraftar Kart'a kaydettiklerinle aynı olmalıdır.";
    }
    return Errors.errorGenericText;
  };

  const handleOkButton = () => {
    if (props.updateUserState.error) {
      props.updateUserReset();
      return;
    }
    props.setApiError();
  }

  return (
    <Modal
      className="api-error-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={isVisible()}
      closable={false}
      footer={false}
      centered
      destroyOnClose
      onCancel={() => props.setApiError()}
    >
      <div className="content">
        <p className="title">{getTitle()}</p>
        <p className="text">{getText()}</p>
        <Button
          className="app-button"
          onClick={handleOkButton}
        >
          Tamam
        </Button>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setApiError,
      updateUserReset
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    apiErrorState: store.setApiError,
    updateUserState: store.updateUser
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorModal);
