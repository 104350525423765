import { Modal } from "antd";
import { Helpers } from "../../../core/utilities/helpers";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./FanCardModal.scss";

interface IProps {
  isVisible: boolean;
  logo: string;
}

const FanCardModal = (props: IProps) => {
  return (
    <Modal
      className="fan-card-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="content">
        <img className="logo" src={props.logo} alt="cark" />

        <p className="title">Çark-ı Fener Her Hafta Taraftar Kart’ta!</p>

        <p className="text">
          Her hafta hediye kazanmak için hemen Taraftar Kart’lı ol!
        </p>
        <ButtonPrimary
          text="TARAFTAR KART'A GİT"
          callback={() => Helpers.goToTaraftarCard()}
        />
        {/* <ButtonSecondary
          class="dark-blue"
          text="GERİ DÖN"
          callback={() => (window.location.href = Constants.fenerLink)}
        /> */}
      </div>
    </Modal>
  );
};

export default FanCardModal;
