import { Button, Spin } from "antd";
import { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import { WheelData } from "react-custom-roulette/dist/components/Wheel/types";
import buttonGif from "../../../assets/images/wheel-button.gif";
import "./Wheel4p.scss";

interface IProps {
  wheelData: WheelData[];
  winnerIndex: number;
  callbackFinished: () => void;
}

const Wheel4P = (props: IProps) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [isFinished, setFinished] = useState(false);

  const handleSpin = () => {
    setMustSpin(true);
  }

  const handleStop = (): void => {
    setFinished(true);
    props.callbackFinished();
  }

  return (
    <div id="wheel4p">
      <Wheel
        mustStartSpinning={mustSpin}
        data={props.wheelData}
        prizeNumber={props.winnerIndex}
        onStopSpinning={handleStop}
        outerBorderColor="#002d72"
        outerBorderWidth={10}
        innerRadius={20}
        innerBorderColor="white"
        innerBorderWidth={5}
        radiusLineColor="#002d72"
        radiusLineWidth={2}
        fontSize={14}
        textDistance={60}
      />
      <Button
        className="button-spin"
        disabled={mustSpin}
        onClick={handleSpin}
      >
        {
          mustSpin
            ? (isFinished ? <Spin size="large" /> : "ÇARKI\nÇEVİR")
            : <img className="button-gif" src={buttonGif} alt="button" />
        }
      </Button>
    </div>
  )
}

export default Wheel4P;
