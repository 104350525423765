import { LoginMsisdnRequest } from './../../../models/requests/loginMsisdn.request';
import { LoginMsisdnDto } from '../../../models/dtos/loginMsisdn.dto';
import axios from '../../../utilities/axios';

export const loginMsisdnRequest = async (loginRequest: LoginMsisdnRequest): Promise<LoginMsisdnDto> => {
  try {
    const response = await axios.post<LoginMsisdnDto>(
      `${process.env.REACT_APP_BASE_URL}/external-wheel/login`,
      loginRequest
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
