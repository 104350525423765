
import { WheelHashDetailDto } from '../../../models/dtos/wheelHashDetail.dto';
import axios from '../../../utilities/axios';

export const getHash = async (hash: string): Promise<WheelHashDetailDto> => {
  try {
    const response = await axios.get<WheelHashDetailDto>(
      `${process.env.REACT_APP_BASE_URL_WHEEL}/tickets?hash=${hash}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
