import { Modal } from "antd";
import parse from "html-react-parser";
import { WheelOfferDto } from "../../../core/models/dtos/wheelOffer.dto";
import { WheelOfferType } from "../../../core/models/enums/wheelOfferType";
import { Constants } from "../../../core/utilities/constants";
import { Helpers } from "../../../core/utilities/helpers";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./WheelCongratsModal.scss";

interface IProps {
  isVisible: boolean;
  prize?: WheelOfferDto;
  callbackClose: () => void;
}

const WheelCongratsModal = (props: IProps) => {
  const handleOk = (): void => {
    switch (props.prize?.type) {
      case WheelOfferType.WALLET_POINT:
        Helpers.sendPostMessage(Constants.walletEvent);
        break;
      default:
        props.callbackClose();
        break;
    }
  };

  const renderBtnText = (): string => {
    switch (props.prize?.type) {
      case WheelOfferType.WALLET_POINT:
        return "İNTERNET CÜZDANINA GİT";
      default:
        return "HEDİYENİ AKTİFLEŞTİR";
    }
  };

  return (
    <Modal
      className="wheel-congrats-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="content">
        <p className="title">TEBRİKLER!</p>

        {props.prize?.imageUrl && (
          <img className="gift" src={props.prize.imageUrl} alt="gift" />
        )}

        <p className="message">
          {parse(props.prize?.name || "")}
          <br />
          KAZANDIN
        </p>

        <p className="text">{parse(props.prize?.description || "")}</p>

        <ButtonPrimary text={renderBtnText()} callback={handleOk} />
      </div>
    </Modal>
  );
};

export default WheelCongratsModal;
