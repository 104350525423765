import { Dispatch } from "redux";
import { ErrorDto } from "../../../models/dtos/error.dto";
import { LoginMsisdnRequest } from "../../../models/requests/loginMsisdn.request";
import { Errors } from "../../../utilities/errors";
import { LocalStorage } from "../../../utilities/localStorage";
import { updateUser } from "../../userService/updateUser/repository";
import { LoginMsisdnDto } from "./../../../models/dtos/loginMsisdn.dto";
import {
  LOGIN_MSISDN_FAIL,
  LOGIN_MSISDN_RESET,
  LOGIN_MSISDN_START,
  LOGIN_MSISDN_SUCCESS,
} from "./constants";
import { loginMsisdnRequest } from "./repository";
import {
  LoginMsisdnFailAction,
  LoginMsisdnResetAction,
  LoginMsisdnStartAction,
  LoginMsisdnSuccessAction,
} from "./types";

const loginMsisdnStartAction = (): LoginMsisdnStartAction => {
  return {
    type: LOGIN_MSISDN_START,
  };
};
const loginMsisdnSuccessAction = (
  payload: LoginMsisdnDto
): LoginMsisdnSuccessAction => {
  return {
    type: LOGIN_MSISDN_SUCCESS,
    payload: payload,
  };
};
const loginMsisdnFailAction = (error: ErrorDto): LoginMsisdnFailAction => {
  return {
    type: LOGIN_MSISDN_FAIL,
    error: error,
  };
};
const loginMsisdnResetAction = (): LoginMsisdnResetAction => {
  return {
    type: LOGIN_MSISDN_RESET,
  };
};

export const loginMsisdn =
  (loginRequest: LoginMsisdnRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(loginMsisdnStartAction());
      const response = await loginMsisdnRequest(loginRequest);
      LocalStorage.set(LocalStorage.token, response.token);
      updateUser({ marketingConsent: loginRequest.marketingConsent });
      dispatch(loginMsisdnSuccessAction(response));
    } catch (error) {
      dispatch(loginMsisdnFailAction(Errors.getErrorDtoFromApiError(error)));
    }
  };

export const loginMsisdnReset = () => (dispatch: Dispatch) => {
  dispatch(loginMsisdnResetAction());
};
