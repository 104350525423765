import { CombinedState, combineReducers, Reducer } from 'redux';
import setApiErrorReducer from '../services/appService/setApiError/reducer';
import { SetApiErrorState } from '../services/appService/setApiError/types';
import setAppMountedReducer from '../services/appService/setAppMounted/reducer';
import { SetAppMountedState } from '../services/appService/setAppMounted/types';
import getActiveWheelReducer from '../services/externalWheelService/getActiveWheel/reducer';
import { GetActiveWheelState } from '../services/externalWheelService/getActiveWheel/types';
import loginMsisdnReducer from '../services/externalWheelService/loginMsisdn/reducer';
import { LoginMsisdnState } from '../services/externalWheelService/loginMsisdn/types';
import sendOtpReducer from '../services/externalWheelService/sendOtp/reducer';
import { SendOtpState } from '../services/externalWheelService/sendOtp/types';
import setRemoteConfigReducer from '../services/firebaseService/setRemoteConfig/reducer';
import { SetRemoteConfigState } from '../services/firebaseService/setRemoteConfig/types';
import getHashReducer from '../services/wheelService/getHash/reducer';
import { WheelHashDetailState } from '../services/wheelService/getHash/types';
import wheelConsumeReducer from '../services/wheelService/wheelConsume/reducer';
import { WheelHashConsumeState } from '../services/wheelService/wheelConsume/types';
import userFetchReducer from './../services/userService/getMe/reducer';
import { UserFetchState } from './../services/userService/getMe/types';
import updateUserReducer from './../services/userService/updateUser/reducer';
import { UpdateUserState } from './../services/userService/updateUser/types';

export interface IStore {
  // APP
  setApiError: SetApiErrorState;
  setAppMounted: SetAppMountedState;
  // EXTERNALWHEEL
  getActiveWheel: GetActiveWheelState;
  sendOtp: SendOtpState;
  loginMsisdn: LoginMsisdnState;
  // FIREBASE
  setRemoteConfig: SetRemoteConfigState;
  // USER
  getMe: UserFetchState;
  updateUser: UpdateUserState;
  // WHEEL
  wheelHashDetail: WheelHashDetailState;
  wheelConsumeHash: WheelHashConsumeState;
}

export const rootReducer: Reducer<CombinedState<IStore>> = combineReducers({
  // APP
  setApiError: setApiErrorReducer,
  setAppMounted: setAppMountedReducer,
  // EXTERNALWHEEL
  getActiveWheel: getActiveWheelReducer,
  sendOtp: sendOtpReducer,
  loginMsisdn: loginMsisdnReducer,
  // FIREBASE
  setRemoteConfig: setRemoteConfigReducer,
  // USER
  getMe: userFetchReducer,
  updateUser: updateUserReducer,
  // WHEEL
  wheelHashDetail: getHashReducer,
  wheelConsumeHash: wheelConsumeReducer,
})
