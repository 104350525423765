import { Dispatch } from "redux";
import { SendOtp } from "../../../models/custom/sendOtp";
import { ErrorDto } from "../../../models/dtos/error.dto";
import { Errors } from "../../../utilities/errors";
import {
  SEND_OTP_FAIL,
  SEND_OTP_RESET,
  SEND_OTP_START,
  SEND_OTP_SUCCESS,
} from "./constants";
import { sendOtpRequest } from "./repository";
import {
  SendOtpFailAction,
  SendOtpResetAction,
  SendOtpStartAction,
  SendOtpSuccessAction,
} from "./types";

const sendOtpStartAction = (): SendOtpStartAction => {
  return {
    type: SEND_OTP_START,
  };
};
const sendOtpSuccessAction = (payload: SendOtp): SendOtpSuccessAction => {
  return {
    type: SEND_OTP_SUCCESS,
    payload: payload,
  };
};
const sendOtpFailAction = (error: ErrorDto): SendOtpFailAction => {
  return {
    type: SEND_OTP_FAIL,
    error: error,
  };
};
const sendOtpResetAction = (): SendOtpResetAction => {
  return {
    type: SEND_OTP_RESET,
  };
};

export const sendOtp = (request: SendOtp) => async (dispatch: Dispatch) => {
  try {
    dispatch(sendOtpStartAction());
    await sendOtpRequest(request.msisdn);
    dispatch(sendOtpSuccessAction(request));
  } catch (error) {
    dispatch(sendOtpFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const sendOtpReset = () => (dispatch: Dispatch) => {
  dispatch(sendOtpResetAction());
};
