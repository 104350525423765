import { Dispatch } from "redux";
import { ErrorDto } from "../../../models/dtos/error.dto";
import { WheelHashDetailDto } from "../../../models/dtos/wheelHashDetail.dto";
import { Errors } from "../../../utilities/errors";
import {
  WHEEL_HASH_DETAIL_FETCH_FAIL,
  WHEEL_HASH_DETAIL_FETCH_RESET,
  WHEEL_HASH_DETAIL_FETCH_START,
  WHEEL_HASH_DETAIL_FETCH_SUCCESS
} from "./constants";
import { getHash } from "./repository";
import {
  WheelHashDetailFetchFailAction,
  WheelHashDetailFetchResetAction,
  WheelHashDetailFetchStartAction,
  WheelHashDetailFetchSuccessAction
} from "./types";

const wheelHashDetailFetchStartAction = (): WheelHashDetailFetchStartAction => {
  return {
    type: WHEEL_HASH_DETAIL_FETCH_START,
  }
}
const wheelHashDetailFetchSuccessAction = (payload: WheelHashDetailDto): WheelHashDetailFetchSuccessAction => {
  return {
    type: WHEEL_HASH_DETAIL_FETCH_SUCCESS,
    payload: payload,
  }
}
const wheelHashDetailFetchFailAction = (error: ErrorDto): WheelHashDetailFetchFailAction => {
  return {
    type: WHEEL_HASH_DETAIL_FETCH_FAIL,
    error: error,
  }
}
export const wheelFetchHashDetail = (hash: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(wheelHashDetailFetchStartAction());
    const hashDetail = await getHash(hash);
    dispatch(wheelHashDetailFetchSuccessAction(hashDetail));
  } catch (error) {
    dispatch(wheelHashDetailFetchFailAction(Errors.getErrorDtoFromApiError(error)));
  }
}

const wheelHashDetailFetchResetAction = (): WheelHashDetailFetchResetAction => {
  return {
    type: WHEEL_HASH_DETAIL_FETCH_RESET,
  }
}
export const wheelResetHashDetail = () => (
  dispatch: Dispatch
) => {
  dispatch(wheelHashDetailFetchResetAction());
}
